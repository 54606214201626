.checkout {
	width: 100%;
	max-width: 380px;
	overflow: hidden;
	background: #fff;
	padding: 10px 16px;
	position: fixed;
	bottom: 0px;
	z-index: 9999;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
	color: #fff;
}
.cart_checkout {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-radius: 5px;
	background: var(--ltPrimary);
	padding: 10px;
	div {
		&:nth-child(1) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
		}
	}
}
.cart_checkout_btn {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px;
	border-radius: 5px;
	background: var(--ltPrimary);
	color: #fff;
	cursor: pointer;
}
