.defaultDoctorCard {
	display: flex;
	flex-direction: column;
	background: #ffffff;
	border: 1px solid #eeeff2;
	border-radius: 10px;
	overflow: hidden;
}

.defaultDoctorCard_img {
	position: relative;
	height: 225px;
	width: 100%;
	background: #cbd5e0;
	flex-wrap: 1;

	img {
		transition:
			box-shadow 0.2s ease,
			transform 0.2s ease;
		&:hover {
			transform: scale(1.03);
		}
	}

	.favoriteIcon {
		position: absolute;
		top: 18px;
		right: 18px;
		// padding: 5px 10px;
	}
}

.defaultDoctorCard_content {
	.bioData {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 10px;
		padding: 20px 15px 10px;

		.name {
			display: flex;
			align-items: center;
			gap: 5px;
		}

		p {
			&:first-child {
				line-height: 16px;
			}
			&:nth-child(2) {
				line-height: 22.4px;
			}
			&:nth-child(3) {
				line-height: 22.4px;
			}
		}
	}

	.experience {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;
		padding: 10px 15px;

		p {
			line-height: 22.5px;
			display: flex;
			align-items: center;
			gap: 6px;
		}
	}

	.prices {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 12px;
		padding: 10px 15px 20px;

		& > div {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			line-height: 27px;
		}
	}
}
