.howWeWorkCard {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	&:last-of-type {
		.howWeWorkCard_icon_cr {
			display: none;
		}
	}
	p {
		&:first-of-type {
			margin-bottom: 20px;
		}
		&:nth-of-type(2) {
			color: #718096;
		}
	}

	.howWeWorkCard_icon_cr {
		position: absolute;
		top: 10px;
		right: -80px;
	}

	.howWeWorkCard_icon_content_wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.howWeWorkCard_icon {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 150px;
			height: 150px;
			border-radius: 50%;
			// background: #f0f1e1;
			color: #ffd023;
			margin-bottom: 30px;
			font-variation-settings:
				'FILL' 1,
				'wght' 700,
				'GRAD' 0,
				'opsz' 48;

			.howWeWorkCard_icon_num {
				position: absolute;
				top: 0;
				right: 0;
				height: 40px;
				width: 40px;
				border-radius: 50%;
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}

// Number Variants
.bgltSecondary {
	background: var(--ltSecondary);
}

.bgltOrange {
	background: var(--ltOrange);
}

.bgltErrorBase {
	background: var(--ltErrorBase);
}

.bgltPrimary {
	background: var(--ltPrimary);
}

// Icon Variants
.bgBlueGreenRules {
	background: #fffae9;
}
.bgPearlDrops {
	background: #fff4ed;
}
.bgFrostyMorning {
	background: #fff0f0;
}
.bgFreeSpirit {
	background: #e7f1f1;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.howWeWorkCard_icon_cr {
		display: none;
	}
	.howWeWorkCard_icon {
		height: 130px;
		width: 130px;
	}
}
