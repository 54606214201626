.offlineDoctorCard {
  display: flex;
  flex-direction: column;
  gap: 30px;
	padding: 30px 70px;
	&_image {
		display: flex;
		align-items: center;
		justify-content: center;
	}
  &_info {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .name{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .availability{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  &_footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
}
