.recentViewDoctorCard {
	width: 100%;
	border: 1px solid var(--ltGreyscale300);
	border-radius: 8px;
	background: #fff;
	box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);

	&_image {
		width: 100%;
		height: 165px;
		border-radius: 10px 10px 0px 0px;
		background: #fff4ed;
		overflow: hidden;
		position: relative;

		.online_chip {
			position: absolute;
			top: 16px;
			left: 0px;
			width: 70.024px;
			height: 24px;
			border-radius: 0px 50px 50px 0px;
			background: var(--ltSuccessDark);
			padding: 1px 2px;
			display: flex;
			justify-content: center;
			align-items: center;

			& > p {
				line-height: 22.4px;
			}
		}
	}

	&_content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		gap: 10px;
		padding: 10px 15px;

		& > p {
			line-height: 19.2 px;
		}

		.name {
			display: flex;
			align-items: center;
			gap: 1px;

			p {
				line-height: 22.4px;
			}
		}
	}
}
