.howWeWork {
	margin-bottom: 30px;
	// background: rgba(29, 160, 153, 0.05);
	background: #ffffff;
	padding: 30px 0px;

	&_head {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		margin-bottom: 30px;

		& > h3 {
			margin-bottom: 0px;
		}
	}

	&_cardRow {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 24px;
		&::-webkit-scrollbar {
			visibility: hidden;
		}
	}
}

// .howwewrok_head_slidericon {
// 	display: flex;
// 	align-items: center;
// 	gap: 24px;
// }

// .howwewrok_cardrow_eplipses {
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	gap: 24px;
// 	margin-top: 60px;
// }
