@mixin container {
	display: flex;
	align-items: center;
	width: 100%;
	border: 1px solid var(--ltGreyscale300);
	border-radius: 6px;
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
	border-radius: 6px;
}

.container {
	@include container;

	.iconContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;

		.icon {
			width: 20px;
			height: 20px;
			color: var(--ltGreyscale500);
		}
	}

	.input {
		width: 100%;
		border: none;
		padding: 8px 10px;
		outline: none;
		font-size: 14px;
	}

	.button {
		padding: 12px 20px;
		border: none;
		background-color: var(--ltPrimary);
		border-radius: 0 6px 6px 0; /* rounded the right corners only */
		color: white;
		font-size: 14px;
		cursor: pointer;
		transition: background-color 0.3s;
		height: 100%;
		font-weight: 600;

		&:hover {
			background-color: #038f5b;
		}
	}
}

.dr_container {
	@include container;
	box-shadow: none;
	background: var(--ltWhite);

	.input {
		background: var(--ltWhite);
	}
}
