.bookAppointmentAction {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;

	.consultation_price {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 10px;

		p {
			line-height: 28px;
		}
	}

	.action_buttons {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
}
