.inHouseDoctorCard_container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	gap: 15px;
	padding: 15px;
	border: 1px solid var(--Greyscale-300, #eeeff2);
	border-radius: 10px;
	background: var(--Others-Base, #fff);
	box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);
}
.image_container {
	min-width: 100px;
	height: 100px;
	border-radius: 50%;
	overflow: hidden;
	border: 1px solid var(--ltGreyscale300);
	border-radius: var(--radius-sm, 6px);
}
.content_container {
	display: flex;
	flex-direction: column;
	gap: 13.5px;
	p {
		&:first-child {
			line-height: 16px;
		}
	}
}
.inHouseDoctorCard_prices {
	display: flex;
	align-items: center;
	gap: 10px;
	& > p {
		color: #111827;
		font-weight: 600;
	}
	& > span {
		font-weight: 400;
		color: #718096;
		text-decoration: line-through;
	}
}
