.paymentDetailsCard {
	position: relative;
	// overflow-y: scroll;
	// border: 1px solid var(--ltGreyscale300);
	// border-radius: 10px;
	// box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);

	&_header {
		// padding: 10px;
		border-bottom: 1px solid var(--ltGreyscale300);
	}

	&.cart {
		border: 1px solid var(--ltGreyscale300);
		border-radius: 6px;
		box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);
	}
}
