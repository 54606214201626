.schedule_container {
	border-radius: 6px;
	border: 1px solid #eeeff2;
	background: #fff;
	margin-bottom: 20px;
	overflow: hidden;
}
.schedule_container_head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #fff;
	padding: 12px 10px;
	border-bottom: 1px solid #eeeff2;
	span {
		display: flex;
		align-items: center;
		gap: 5px;
		cursor: pointer;
	}
}
.schedule_container_text {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
}
.timeSchedule_wrap {
	display: flex;
	align-items: center;
	gap: 10px;
	padding-top: 10px;
	padding-bottom: 20px;
	margin-top: 10px;
	overflow-x: scroll;
	&::-webkit-scrollbar-thumb {
		border-radius: 20px;
		background: var(--Greyscale-500, #a0aec0);
	}
}
.time_slot_btn {
	width: 100%;
	padding: 10px 20px;
	border-radius: 6px;
	border: 1px solid #eeeff2;
	color: #111827;
	font-size: 12px;
	font-weight: 400px;
	line-height: 19.2px;
	text-align: center;
	cursor: pointer;
	&:hover {
		border: 1px solid var(--ltPrimary);
	}
}
.selected_time_slot {
	border: 1px solid var(--ltPrimary);
	background: #0e76731a;
	color: var(--ltPrimary);
}
