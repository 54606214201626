.labOrderCancel {
	width: 100%;
	min-width: 300px;
	max-height: 500px;
	// overflow-y: scroll;
	position: relative;

	.cancelOrder_wrap {
		min-width: 520px;
		.icon_wrap {
			padding: 24px;
			padding-bottom: 0px;
			.icon {
				width: 68px;
				height: 68px;
				background: #fd6a6a1a;
				border-radius: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.content_wrap {
			padding: 8px 24px 16px 24px;
		}

		.cancelReasons_wrap {
			padding: 16px 24px 16px 24px;
		}
	}

	.orderCancel_btn_action {
		width: 100%;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;
		border-top: 1px solid var(--Greyscale-300, #eeeff2);
		border-radius: 0px 0px 10px 10px;
		padding: 24px;
		position: sticky;
		bottom: 0;

		&.button_container_orderCancel {
			padding: 24px;

			.orderCancel_btn_action {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 12px;
			}
		}
	}
}

.lists_wrap {
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.listItem {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
